import { render, staticRenderFns } from "./FormV.vue?vue&type=template&id=006e7058&scoped=true&"
import script from "./FormV.vue?vue&type=script&lang=js&"
export * from "./FormV.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "006e7058",
  null
  
)

export default component.exports