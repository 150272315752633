<template>
  <b-container fluid>
    <iq-card>
      <template v-slot:body>
      <b-row>
        <b-col xs="12" sm="12" md="6">
            <ValidationProvider name="Organization" rules="required|min_value:1">
              <b-form-group
                class="row"
                label-cols-sm="3"
                label-for="org_id"
                slot-scope="{ valid, errors }"
                >
                <template v-slot:label>
                  {{$t('seedsConfig.organization')}} <span class="text-danger">*</span>
                  </template>
                <b-form-select
                  plain
                  v-model="search.org_id"
                  :options="orgList"
                  id="org_id"
                  :state="errors[0] ? false : (valid ? true : null)"
                  >
                  <template v-slot:first>
                    <b-form-select-option :value="0" disabled>{{$t('globalTrans.select')}}</b-form-select-option>
                  </template>
                </b-form-select>
                <div class="invalid-feedback">
                  {{ errors[0] }}
                </div>
              </b-form-group>
            </ValidationProvider>
        </b-col>
        <b-col xs="12" sm="12" md="6">
            <b-button type="button" variant="primary" @click="searchData">{{ $t('globalTrans.search')}}</b-button>
        </b-col>
      </b-row>
      </template>
    </iq-card>
    <b-row>
      <b-col md="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">{{ $t('warehouse_config.report_heading') }}</h4>
          </template>
          <template v-slot:headerAction>
            <!-- <b-button variant="primary" v-b-modal.modal-4 @click="resetId">
              {{  $t('globalTrans.add_new') }}
            </b-button> -->
            <b-button class="btn_add_new" size="sm" v-b-modal.modal-4 @click="resetId">
              <i class="fas fa-plus"></i>{{ $t('globalTrans.add_new') }}
            </b-button>
          </template>
          <template v-slot:body>
            <b-overlay :show="loadingState">
              <b-row>
                <b-col md="12" class="table-responsive">
                  <b-table thead-class="table_head" bordered striped hover :items="listData" :fields="columns" aria-hidden="loading | listReload ? 'true' : null" :emptyText="$t('globalTrans.noDataFound')" show-empty>
                    <template v-slot:cell(index)="data">
                      {{ $n(data.index + pagination.slOffset) }}
                    </template>
                    <template v-slot:cell(heading)="data">
                      {{ data.item.heading }}
                    </template>
                    <template v-slot:cell(left_logo)="data">
                      <b-img width='100px' :src="seedFertilizerServiceBaseUrl+'storage/report-heading/left-logo/'+data.item.left_logo" fluid alt="Left Logo"></b-img>
                    </template>
                    <template v-slot:cell(right_logo)="data">
                      <b-img width='100px' :src="seedFertilizerServiceBaseUrl+'storage/report-heading/right-logo/'+data.item.right_logo" fluid alt="Right Logo"></b-img>
                    </template>
                    <template v-slot:cell(address)="data">
                      {{ data.item.address }}
                    </template>
                    <template v-slot:cell(org_id)="data">
                      {{ data.item.org_name }}
                    </template>
                    <template v-slot:cell(status)="data">
                        <span class="badge badge-success" v-if="data.item.status == 1">{{$t('globalTrans.active')}}</span>
                        <span class="badge badge-danger" v-else>{{$t('globalTrans.inactive')}}</span>
                    </template>
                    <template v-slot:cell(action)="data">
                      <!-- <b-button v-b-modal.modal-4 variant="iq-bg-success mr-1 mb-1" size="sm" @click="edit(data.item)"><i class="ri-ball-pen-fill m-0"></i></b-button>
                      <span>
                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fas fa-window-close m-0"></i></b-button>
                      </span>
                      <span v-if="data.item.status === 0">
                        <b-button variant=" iq-bg-danger" size="sm" @click="remove(data.item)"><i class="fa fa-check m-0"></i></b-button>
                      </span> -->
                      <a href="javascript:" class="btn_table_action table_action_edit" title="Edit" v-b-modal.modal-4 size="sm" @click="edit(data.item)">
                          <i class="ri-ball-pen-fill"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 1">
                          <i class="fas fa-toggle-on"></i>
                      </a>
                      <a href="javascript:" class="btn_table_action table_action_toggle" title="Status" size="sm" @click="remove(data.item)" v-if="data.item.status === 2">
                          <i class="fa fa-toggle-off"></i>
                      </a>
                    </template>
                  </b-table>
                  <b-pagination
                    align="center"
                    v-model="pagination.currentPage"
                    :per-page="pagination.perPage"
                    :total-rows="pagination.totalRows"
                    @input="searchData"
                    />
                </b-col>
              </b-row>
            </b-overlay>
          </template>
        </iq-card>
      </b-col>
    </b-row>
    <b-modal id="modal-4" size="lg" :title="formTitle" :ok-title="$t('globalTrans.close')" ok-only ok-variant="danger">
      <p>
        <FormV :id="editItemId" :key="editItemId"/>
      </p>
    </b-modal>

  </b-container>
</template>
<script>
import FormV from './FormV'
import RestApi, { seedFertilizerServiceBaseUrl } from '@/config/api_config'
import { reportHeadingList, reportToggleStatus } from '../../api/routes'
import ModalBaseMasterList from '@/mixins/modal-base-master-list'

export default {
  mixins: [ModalBaseMasterList],
  components: {
    FormV
  },
  data () {
    return {
      search: {
        org_id: this.$store.state.dataFilters.orgId
      },
      rows: [],
      seedFertilizerServiceBaseUrl: seedFertilizerServiceBaseUrl
    }
  },
  computed: {
    formTitle () {
       return (this.editItemId === 0) ? this.$t('warehouse_config.report_heading_entry') : this.$t('warehouse_config.report_heading') + ' ' + this.$t('globalTrans.modify')
    },
    columns () {
      const labels = [
          { label: this.$t('globalTrans.sl_no'), class: 'text-left' },
          { label: this.$t('seedsConfig.organization'), class: 'text-left' },
          { label: this.$t('warehouse_config.project_name'), class: 'text-left' },
          { label: this.$t('warehouse_config.address'), class: 'text-left' },
          { label: this.$t('warehouse_config.left_logo'), class: 'text-left' },
          { label: this.$t('warehouse_config.right_logo'), class: 'text-left' },
          { label: this.$t('globalTrans.status'), class: 'text-center' },
          { label: this.$t('globalTrans.action'), class: 'text-center' }
        ]

      let keys = []

      if (this.$i18n.locale === 'bn') {
        keys = [
          { key: 'index' },
          { key: 'org_name_bn' },
          { key: 'project_name_bn' },
          { key: 'address_bn' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      } else {
        keys = [
          { key: 'index' },
          { key: 'org_name' },
          { key: 'project_name' },
          { key: 'address' },
          { key: 'left_logo' },
          { key: 'right_logo' },
          { key: 'status' },
          { key: 'action' }
        ]
      }

      return labels.map((item, index) => {
          return Object.assign(item, keys[index])
        })
    },
    divisionList: function () {
      return this.$store.state.commonObj.divisionList.filter(item => item.status === 0)
    },
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList
    }
  },
  created () {
    this.loadData()
  },
  methods: {
    searchData () {
      this.loadData()
    },
    remove (item) {
      this.changeStatus(seedFertilizerServiceBaseUrl, reportToggleStatus, item)
    },
    loadData () {
      const params = Object.assign({}, this.search, { page: this.pagination.currentPage, per_page: this.pagination.perPage })
      this.$store.dispatch('mutateCommonProperties', { loading: true, listReload: false })
      RestApi.getData(seedFertilizerServiceBaseUrl, reportHeadingList, params).then(response => {
        if (response.success) {
          this.$store.dispatch('setList', this.dataList(response.data.data))
          this.paginationData(response.data)
        }
      })
      this.$store.dispatch('mutateCommonProperties', { loading: false, listReload: false })
    },
    dataList (data) {
      const orgList = this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
      let tmpData = {}
      const listData = data.map(item => {
        tmpData = orgList.find(orgItem => orgItem.value === item.org_id)
        const orgData = {
          org_name: tmpData !== undefined ? tmpData.text_en : '',
          org_name_bn: tmpData !== undefined ? tmpData.text_bn : ''
        }
        return Object.assign({}, item, orgData)
      })
      return listData
    }
  }
}
</script>
